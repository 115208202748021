import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage
            src="../images/img_male.jpg"
            alt=""
            placeholder="blurred"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="mb-5">Our Procedures for Men</h1>
          <h2 className="mb-4">Body</h2>
          <h3 className="fs-5">Butt Lift / Butt Augmentation</h3>
          <p>
            With this treatment, you can enlarge, re-shape or lift your
            buttocks.
          </p>
          <h3 className="fs-5">Liposuction</h3>
          <p>
            The liposuction is one of the most popular beauty treatments. With
            this treatment, you can quickly and safely remove excess fat from
            almost all body parts.
          </p>

          <h3 className="fs-5">Penis Enlargement</h3>
          <p>
            Applying the newest surgical methods, penis enlargements up to 40 %
            in length and 30 % in gauge are possible. All our treatments ensure
            that you won't experience any limits in your sexual activities
            afterwards.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
